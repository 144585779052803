body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.App {
  width: 292px;
  max-width: 292px;
}
.note-list {
  width: 80%;
  margin: 0px auto;
  border: solid #E2E3E4 1px;
  text-align: left;
  padding: 13px 10px;
  border-radius: 10px;
  cursor: pointer;

}
.note-list h2 {
  margin-top: 0;
  margin-bottom: 0;
  font-size: 13px;
  font-weight: 600;
  color: #424548;
  padding-left: 3px;
  padding-right: 10px;
  font-family: 'THSarabunNew';
  font-weight: bolder;
}
.group span {
  background: #F9E799;
  padding: 1px 9px;
  margin: 0px 0px;
  display: inline-block;
  border-radius: 10px;
  font-size: 8px;
  font-weight: bold;
}
.note-short-detail {
  font-size: 13px;
  padding-right: 20px;
}
