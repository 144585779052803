
@import url(fonts/thsarabunnew.css);
body {
  font-family: 'THSarabunNew';
}

.App {
  text-align: center;
  background: #F5F5F5;
  padding: 5px;
  float: left;
}
li.palette.focus {
  border: solid 2px black;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
.note_view_content .header_area{
  float: left;
  display: block;
  width: 100%;
}
.note_view_content .group {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}
.note_view_content .note_title {
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 500;
  background: transparent;
  border: inherit;
}
.note_view_content .note_description {
  width: 100%;
  float: left;
  word-wrap: break-word;
  overflow-wrap: anywhere;
}
.note_view_content .note_view_content .group {
  float: left;
  display: block;
  width: 100%;
}
.note_view_content .group{
  display: block;

}
.note_view_content .header_area  .back{
  float: left;
  cursor: pointer;
  }
  .note_view_content .header_area .save{
    float: right;
  }
  .note_view_content .view_content_area {
    display: block;
    float: left;
    margin-top: 21px;
    text-align: left;
    padding: 10px;
}
.note_view_content{
  position: relative;
  float: left;
  display: block;
}
.note_view_content .group:after {
  display: block;
  content: '';
  background: #8B8F93;
  height: 1px;
  margin-top: 10px;
}
.note_view_content .file_area #getFile{
  display: none;
}
.note_view_content .file_area {
  display: block;
  position: absolute;
  right: 0;
  bottom: 0px;
  z-index: 1111;
  width: 50px;
}
.setting {
  position: absolute;
  right: 7px;
  top: 5px;
  cursor: pointer;
}
.group_color {
  margin-left: 4px;
  display: inline-block;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-right: 8px;
}

.save button {
  background: #32A3AA;
  color: white;
  font-size: 10px;
  padding: 6px 10px;
  border: inherit;
  border-radius: 5px;
}
.note_view_content .group select {
  border: solid 1px #E2E3E4;
  border-radius: 11px;
  font-size: 13px;
}

.note-list {
  width: 80%;
  height: 103px;
  margin: 0px auto;
  border: solid #E2E3E4 1px;
  text-align: left;
  padding: 13px 10px 6px 13px;
  border-radius: 10px;
  background: #f3f7fb;
  position: relative;
  margin-bottom: 10px;
}
.group_list_data li {
  cursor: pointer;
}
ul.group_list {
  font-size: 14px;
  width: 80%;
  height: 32px;
  border: solid 1px #E2E3E4;
  border-radius: 10px;
  position: relative;
  margin-left: 28px;
  list-style: none;
  text-align: left;
}
li.setting_list {
  border-style: solid;
  border-width: 2px 0px 0px 0px;
  border-color: #E2E3E4;
  cursor: pointer;
}

li.setting_list span svg {
  font-size: 15px;
}
li.setting_list span {
  width: 20px;
  margin-right: 4px;
  margin-top: 5px;
  display: inline-block;
  text-align: center;
}
ul.group_list_data {
  background: white;
  position: absolute;
  width: 222px;
  list-style: none;
  padding: 8px 0px 8px 15px;
  border-radius: 15px;
  top: 33px;
  left: -6px;
  z-index: 999;
}
ul.list_group {
  padding: 0 10px;
  text-align: left;
  list-style: none;
}
ul.list_group li:after {
  display: block;
  content: '';
  height: 2px;
  background: #E2E3E4;
  margin-top: 6px;
}
ul.list_group .group_icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  vertical-align: super;
  position: relative;
  top: 0px;
}
.group_icon_preset{
  display: inline-block;
  width: 20px;
  text-align: center;
  height: 20px;
  cursor: pointer;
}
ul.list_group li {
  margin-bottom: 10px;
}
strong.icon_group {
  float: right;
  padding-top: 3px;
}
.view_area {
  border: solid 1px #8B8F93;
  width: 89%;
  margin: 0px auto;
  border-radius: 5px;
  position: relative;
}
.view_area .preview {
  color: #8B8F93;
  position: absolute;
  left: 9px;
  background: #F5F5F5;
  display: block;
  top: -10px;

}
.action_area .button_group button {
  padding: 7px 15px;
}
.action_area .button_group .save {
  background: #32A3AA;
  border-radius: 9px;
  color:white;
}

.view_note_group .header_area .back {
    text-align: left;
    margin-bottom: 15px;
    padding-left: 13px;
}
.view_note_group .header_area .back span {
  margin-left: 5px;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

ul.color li {
  border-radius: 50%;
  width: 23px;
  display: inline-block;
  height: 23px;
  margin-right: 4px;
  cursor:pointer;
}
.list_color ul {
  list-style: none;
  padding: 0 0;
}
.group_data .info {
  text-align: left;
  padding-left: 17px;
  padding-top: 17px;
}
.group_label {
  text-align: left;
  padding-left: 18px;
  padding-top: 9px;
  padding-bottom: 10px;
}
.group_label span {
  font-size: 12px;
  border-radius: 4px;
  padding: 3px 9px;
}
.edit_area input {
  width: 100%;
  display: block;
  padding: 6px 4px;
  border-radius: 5px;
  margin-top: 47px;
  border: solid 1px #9aabbc;
  background: transparent;
}
.edit_area {

  margin: 0px auto;
}
.action_area .button_group{
  float: right;
}
.action_area button {
  float: left;
  padding: 6px;
  border: solid 1px #E2E3E4;
  background: transparent;
}
.modal_content h2 {
  font-size: 20px;
}
.modal_content {
  font-size: 10px;
}
.modal_content h2 {
  font-size: 12px;
  text-align: center;
}
.action .delete {
  color: red;
}
.modal_content .action {
  float: right;
}
.modal_delete {
  position: absolute;
  width: 223px;
  z-index: 6;
  background: white;
  padding: 10px;
}
.modal_content h2 {
  font-size: 12px;
  text-align: center;
}
.action button.btn {
  font-size: 14px;
}
.status.complete {
  background: green;
  color: white;
  padding: 5px;
  margin-top: 10px;
  width: 143px;
  text-align: center;
}
.date_update {
  font-size: 13px;
  margin-bottom: 8px;
}
.add_note {
  text-align: right;
  padding-right: 0px;
  padding-top: 10px;
}
.add_note span {
  background: #BA0020;
  display: inline-block;
  width: 35px;
  height: 35px;
  font-size: 23px;
  text-align: center;
  color: white;
  border-radius: 50%;
  cursor: pointer;
}
.data_save .back_to_group {
  color: coral;
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.data_save {
  background: green;
  color: white;
  padding: 15px;
  font-size: 13px;
}
.remove_note {
  position: absolute;
  right: 7px;
  top: 3px;
  cursor: pointer;
  display: none;
}
.note-short-detail {
  font-size: 10px;
  padding-right: 16px;
  padding-left: 4px;
}

.note-list:hover .remove_note {
  display: block;
}

ul#list_group li .group_name {
    font-size: 13px;
    position: relative;
    top: -4px;
    font-weight: bold;
}
.header_area.list_group {
  text-align: left;
  padding-left: 10px;
  margin-bottom: 13px;
  margin-top: 8px;
}
.header_area.list_group .back svg {
  font-size: 15px;
}
.header_area.list_group .header_label {
  margin-left: 13px;
  font-size: 12px;
  position: relative;
  top: 0px;
  font-weight: 700;
}
.add_note span img {
  width: 100%;
}
.note_view_content .group select {
  border: solid 1px #E2E3E4;
  border-radius: 11px;
  font-size: 13px;
  text-indent: 8px;
}
div#description:focus-visible {
  outline: inherit;
}
ul.group_list li {
  font-size: 12px;
  position: relative;
  right: 16px;
  top: 6px;

}
.thumbnail {
  width: 20%;
  height: 50px;
  float: left;
  background: white;
  border: solid 1px #afa6a6;
  overflow: hidden;
  position: relative;
}
.images {
  width: 100%;
  float: left;
}
.thumbnail img {
  width: 100%;
}
.images{
  padding-left: 14px;

}
.images h4 {
  font-size: 15px;
  font-weight: 700;
  text-align: left;
}
.delete_thumb {
  position: absolute;
  right: 0;
  background: #8d0303;
  width: 20px;
  height: 20px;
  color: white;
  border-radius: 50px;
  font-size: 12px;
  cursor: pointer;
}
span.delete_thumb svg {
  vertical-align: middle;
}
.note_thumbnail {
  display: inline-block;
  width: 55px;
  height: 55px;
  border: solid 1px #a9a9a95c;
  overflow: hidden;
  position: absolute;
  top: 25px;
}
.note_content {
  width: 150px;
  display: inline-block;
}
.note_thumbnail img {
  width: 100%;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
